<template>
<div class="lsp">
  <lsp_page_reimagine />
  </div>
</template>

<script>
// @ is an alias to /src
import lsp_page_reimagine from "@/components/lsp_page_reimagine.vue";

export default {
  name: "lsp-social",
  components: { lsp_page_reimagine },
};
</script>
