import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import DomainPriceView from '@/views/DomainPriceView.vue'
import PGPoolerFAQView from '@/views/PGPoolerFAQView.vue'
import CorsFAQView from '@/views/CorsFAQView.vue'
import ResponseHeadersFAQView from '@/views/ResponseHeadersFAQView.vue'
import ParserFAQView from '@/views/ParserFAQView.vue'
import SecurityFAQView from '@/views/SecurityFAQView.vue'
import MomentFAQView from '@/views/MomentFAQView.vue'
import NodeFetchFAQView from '@/views/NodeFetchFAQView.vue'
import LspView from '@/views/LSPView.vue'
import LspReInventView from '@/views/LspReInventView.vue'
import LSPReIngageView from '@/views/LSPReIngageView.vue'
import LSPReImagineView from '@/views/LSPReImagineView.vue'
import LSPGrowView from '@/views/LSPGrowView.vue'
import LSPReflectView from '@/views/LSPReflectView.vue'
import AWSPySnippetsView from '@/views/AWSPySnippetsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/domains',
    name: 'domains',
    component: DomainPriceView
  },
  {
    path: '/cloud-out-loud-pgpooler',
    name: 'pg_pooler_page',
    component: PGPoolerFAQView 
  },
  {
    path: '/cloud-out-loud-cors',
    name: 'cors_page',
    component: CorsFAQView 
  },
  {
    path: '/cloud-out-loud-response-header-config',
    name: 'response_headers_page',
    component: ResponseHeadersFAQView 
  },
  {
    path: '/cloud-out-loud-body-parser',
    name: 'body_parser_page',
    component: ParserFAQView 
  },
  {
    path: '/cloud-out-loud-security',
    name: 'security_page',
    component: SecurityFAQView 
  },
  {
    path: '/cloud-out-loud-moment',
    name: 'moment_page',
    component: MomentFAQView 
  },
  {
    path: '/cloud-out-loud-node-fetch',
    name: 'node_fetch_page',
    component: NodeFetchFAQView 
  },
  {
    path: '/lsp',
    name: 'LspView',
    component: LspView 
  },
  {
    path: '/lspreinvent',
    name: 'LspReInventView',
    component: LspReInventView 
  },
  {
    path: '/lspreengage',
    name: 'LSPReIngageView',
    component: LSPReIngageView 
  },
  {
    path: '/lspreimagine',
    name: 'LSPReImagineView',
    component: LSPReImagineView 
  },
  {
    path: '/lspgrow',
    name: 'LSPGrowView',
    component: LSPGrowView 
  },
  {
    path: '/lspreflect',
    name: 'LSPReflectView',
    component: LSPReflectView 
  },
  {
    path: '/aws_py_snippets',
    name: 'AWSPySnippetsView',
    component: AWSPySnippetsView 
  }
  
  
]

const router = createRouter({
   history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
