<template>
<div class="lsp">
  <lsp_page_reflect />
  </div>
</template>

<script>
// @ is an alias to /src
import lsp_page_reflect from "@/components/lsp_page_reflect.vue";

export default {
  name: "lsp-health",
  components: { lsp_page_reflect },
};
</script>
