<template>
  <div>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
     -->
    <div id="navigation-bar">
      <nav_component />
    </div>
    <router-view />
    <footer_component banner_text="AWS solutions architecture" />
  </div>
</template>

<script>
// @ is an alias to /src
import main_page from "@/components/main_page.vue";
import nav_component from "@/components/banner.vue";
import footer_component from "@/components/footer.vue";

export default {
  name: "Home",
  components: { main_page, nav_component, footer_component },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #343A40;
  margin-top: 80px;
  margin-bottom: 60px;
  padding-bottom: 75px;
  /* margin-left: 5px;
  margin-right: 5px; */
  background-color: #ffffff;
  /* background-image: url("@/assets/images/Your_digital_newspaper_of_tomorrow.png"); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}

.lsp-div-background {
  color: #343A40;
  font-size: 50px;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  background-image: url("@/assets/images/Your_digital_newspaper_of_tomorrow.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.lsp-page-heading {
  color: #343A40;
  font-size: 50px;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.lsp-page-sub-heading {
  color: #4e8253;
  font-size: 12px;
  font-weight: normal;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.lsp-page-text-context {
  color: #343A40;
  font-size: 14px;
  font-weight: normal;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.lsp-page-text-highlight {
  color: #343A40;
  font-size: 20px;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}



#navigation-bar {
  margin-bottom: 5px;
}

.linked-image {
  cursor: pointer;
}


.lsp-image {
  border-radius: 20px;
  width: auto;
  height: auto;
}
</style>
