<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
      <div class="col-sm-block col-md-block col-lg-10 col-xl-10" style="text-align: center">
        <span class="lsp-page-heading"><i class="fa-solid fa-gears"></i> REENGAGE</span><br />
        <span class="lsp-page-text-highlight"
          >The ability to reengage is a pivotal skill that breathes life into stagnant situations and rejuvenates teams.</span
        ><br />
        <span class="lsp-page-sub-heading">
          In the face of challenges or periods of disconnection, reengagement involves a deliberate effort to reignite passion, motivation, and
          collaboration. Effective leaders recognize the importance of reconnecting with their team members, addressing concerns, and renewing a
          shared sense of purpose. It's about rekindling enthusiasm, fostering open communication, and creating an environment where everyone feels
          valued and motivated to contribute their best. Through the art of reengagement, leaders not only revive the spirit of their teams but also
          pave the way for renewed productivity, innovation, and a collective drive towards shared goals. <br />
        </span>
      </div>
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
    </div>

    <div class="row">
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
      <div class="col-sm-block col-md-block col-lg-5 col-xl-5" style="text-align: center">
        <div class="card">
          <img class="card-img-top" src="../assets/images/wagon_at_work.png" alt="art1img5" />
          <div class="card-body">
            <h5 class="card-title"><b>Get back on the dating “WAGON” at work</b><br/><a href="https://youtu.be/-KDy3plgfoA?list=PLQxQbA8cZU5b92Pl_nS-cFFDe6sZ71-Df"  target="_blank">Link to the W.A.G.O.N Video</a></h5>
            <p class="card-text">
              <br />

              In the dynamic realm of professional life, the heartbeat of any successful team lies in the connections amongst team members. Striving
              for shared objectives and collective success necessitates a deeper understanding of the unique aspirations, motivations, and dreams
              driving each team member. <br />
              <br />
              Dr. Stanley Bongwe introduces the WAGON method - an acronym representing Wish, Achievement, Goal, Opportunity, and Name. This method
              acts as powerful tool, weaving the fabric of understanding and camaraderie within a team. By delving into these five key aspects, we
              embark on a journey that goes beyond the surface level, allowing us to comprehend the intricacies that make each colleague a vital
              contributor to collective success. <br />
              <br />
              Spend just 5 minutes of your day “speed-dating” your colleague. Utilising the WAGON method to help transform the way you perceive your
              colleague, you may unravel layers that go beyond job titles and daily tasks, unlocking the door to a more conducive an empathetic work
              environment. In the spirit of a first date, your team member seeks to feel heard, valued and understood – making the connection more
              than just a buzzword but a lived experience in collaboration. <br />
              <br />
              Unsure of where to start? View an example of the Wagon Method interview here <br />
            </p>
          </div>
        </div>
        <br />
        <br />
        <div class="card">
          <img class="card-img-top" src="../assets/images/art1img3.png" alt="art1img2" />
          <div class="card-body">
            <h5 class="card-title"><b>Strategic Brilliance Unveiled: Applying Sun Tzu's Art of War in the Corporate Landscape</b></h5>
            <p class="card-text">
              In a world where businesses are engaged in constant battles for market supremacy, corporate leaders are increasingly turning to ancient
              wisdom for guidance. The timeless principles of Sun Tzu's "The Art of War" are finding new relevance in the fast-paced and competitive
              landscape of the business world.<br />
              <br />
              Sun Tzu, the ancient Chinese military strategist and philosopher, wrote "The Art of War" over 2,500 years ago. While originally a
              treatise on military strategy, its principles have been adapted and applied in various fields, including business. The strategic
              insights provided by Sun Tzu are proving to be invaluable in navigating the complexities of corporate warfare.<br />
              <br />
              One of the central tenets of Sun Tzu's philosophy is the importance of understanding both oneself and the adversary. In the corporate
              realm, this translates to a deep understanding of the company's strengths, weaknesses, opportunities, and threats, as well as a
              comprehensive analysis of competitors. Successful leaders recognize that a thorough understanding of the playing field is crucial for
              developing effective strategies.<br />
              <br />
              Sun Tzu's emphasis on adaptability and flexibility is also highly relevant in the corporate landscape. The business environment is
              dynamic, and the ability to adjust strategies in response to changing conditions is paramount. According to Sun Tzu, "All warfare is
              based on deception." In the business world, this means staying ahead of the competition by being agile and unpredictable.<br />
              <br />
              Furthermore, the importance of strategic positioning is a key takeaway from "The Art of War." Sun Tzu famously said, "All warfare is
              based on deception. Hence, when we are able to attack, we must seem unable; when using our forces, we must appear inactive; when we are
              near, we must make the enemy believe we are far away." This wisdom is reflected in the corporate world, where companies often
              strategically position themselves in the market, creating an image that may not necessarily reflect their true capabilities.<br />
              <br />
              Effective communication is another vital aspect emphasized by Sun Tzu. Clear and concise communication within an organisation ensures
              that everyone is aligned with the overall strategy. Sun Tzu recognized the importance of a well-informed and coordinated team, stating,
              "The general who wins the battle makes many calculations in his temple before the battle is fought. The general who loses makes but few
              calculations beforehand."<br />
              <br />
              In conclusion, the application of Sun Tzu's "The Art of War" in the corporate landscape is proving to be a game-changer for many
              businesses. By embracing the ancient wisdom of strategic thinking, adaptability, and effective communication, corporate leaders are
              positioning their organisations for success in an ever-evolving and competitive market. As Sun Tzu aptly said, "The greatest victory is
              that which requires no battle."<br />
            </p>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div class="col-sm-block col-md-block col-lg-5 col-xl-5" style="text-align: center">
        <div class="card">
          <img class="card-img-top" src="../assets/images/art1img4.png" alt="art1img5" />
          <div class="card-body">
            <h5 class="card-title"><b>Revitalizing the Code: A Journey to Holistic Health for the IT Warrior</b></h5>
            <p class="card-text">
              <br />
              <b>Introduction</b>
              <br />
              In the fast-paced world of Information Technology, where lines of code and deadlines reign supreme, it's easy for health to take a
              backseat. Meet Robert, a seasoned IT professional in his late 40s, who decided it was time to break the mold and embark on a journey
              towards holistic well-being. Guided by the 6 Q's of leadership—Intelligence Quotient (IQ), Emotional Quotient (EQ), Spiritual Quotient
              (SQ), Physical Quotient (PQ), Financial Quotient (FQ), and Network Quotient (NQ)—Robert is rewriting his life's code, prioritizing his
              health in ways he never thought possible.
              <br />
              <br />
              <b>Details</b>
              <br />
              <b>Intelligence Quotient (IQ)</b> and Health In the tech world, a sharp mind is the ultimate asset. For Robert, linking his Intelligence
              Quotient to his health was a revelation. He delved into brain-boosting activities, from learning new programming languages to solving
              puzzles. Embracing a nutrient-rich diet that fuels cognitive function became his mantra. In this section, we'll explore how optimizing
              IQ can lead not only to professional success but also to sustained mental well-being.
              <br />
              <br />
              <b>Emotional Quotient (EQ)</b> and Stability In the world of zeros and ones, emotions can sometimes be perceived as vulnerabilities.
              However, Robert recognized that emotional stability is the bedrock of a successful life. By embracing Emotional Quotient, he learned to
              navigate stress and foster healthier relationships, both at work and at home. This section delves into the importance of acknowledging
              and expressing emotions, offering insights into how emotional intelligence can bring stability to the chaotic landscape of IT.
              <br />
              <br />
              <b>Spiritual Quotient (SQ)</b> and Esoteric Practices The server rooms and coding languages may seem worlds apart from esoteric
              practices, but Robert found a profound connection. Exploring his Spiritual Quotient, he incorporated mindfulness and meditation into his
              routine. From moments of reflection to the practice of gratitude, this section uncovers how esoteric practices can serve as a compass in
              the often tumultuous world of IT, offering a sanctuary for mental and spiritual rejuvenation.
              <br />
              <br />
              <b>Physical Quotient (PQ)</b> and Physical Activities Breaking free from the sedentary shackles of an IT job, Robert discovered the
              importance of Physical Quotient. Engaging in regular physical activities became his secret weapon against aches, pains, and the monotony
              of sitting at a desk. From incorporating short walks into his workday to embracing a tailored workout routine, this section explores how
              prioritizing physical health has transformed Robert's life.
              <br />
              <br />
              <b>Financial Quotient (FQ)</b> and Wealth Management In the IT realm, financial success often comes hand in hand with career
              achievements. Robert, however, realized that Financial Quotient goes beyond making money—it's about managing it wisely. This section
              unravels his journey in striking a balance between ambition and financial well-being, offering practical tips on budgeting, investing,
              and aligning financial goals with a healthier lifestyle.
              <br />
              <br />
              <b>Network Quotient (NQ)</b> and Personal Relationships Amidst lines of code and tech jargon, personal connections can be overlooked.
              Not for Robert. Recognizing the importance of Network Quotient, he actively nurtured professional and personal relationships. This
              section delves into the strategies he employed, from mentorship in the IT community to cultivating meaningful connections outside of
              work. Discover how a strong network can be a pillar of support in the pursuit of a healthier and more fulfilling life.
              <br />
              <br />
              <b>Conclusion</b>
              <br />
              As we conclude Robert's journey to holistic health, we see a man who has cracked the code of well-being in the IT world. The 6 Q's of
              leadership—Intelligence, Emotional, Spiritual, Physical, Financial, and Network—have become guiding principles, transforming not only
              his health but also his entire approach to life. Robert's story serves as an inspiration to IT professionals everywhere, proving that
              the pursuit of well-being is not only compatible with a successful career but an essential component of it. The journey to holistic
              health for the IT warrior is not just a possibility—it's a necessity.
              <br />
            </p>
          </div>
        </div>
        <br />
        <br />
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>Kevin Britz - The Lunchtime Series</b></h5>
            <p class="card-text">
              "Welcome to Lunchtime Series 2023 where we aim to add value to people's lives happening every Wed & Thurs on eBizradio.com We chat about
              everything leadership, coaching, and marketing and you can catch the latest Lunchtime Series on all major podcast channels today."
              ~Kevin Britz<br />
              <br />
              <br />
              <a href="https://www.leadershipbydesign.co/" target="_blank">"Leadership by design" with Kevin</a> <br />
              <br />
            </p>
            <iframe
              width="350"
              height="315"
              src="https://www.youtube.com/embed/0B1JhJ8cCxE"
              title="The Lunchtime Series with Kevin Britz -  Leadership"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
    </div>

  </div>
</template>
