<template>
<div class="parser">
  <body_parser_page />
  </div>
</template>

<script>
// @ is an alias to /src
import body_parser_page from "@/components/body_parser_page.vue";

export default {
  name: "parser",
  components: { body_parser_page },
};
</script>
