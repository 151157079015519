<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <div class="card text-left">
          <div class="card-header">
            <h4 class="mb-0"><i class="fa-solid fa-circle-info"></i> Documentation.</h4>
          </div>
          <div class="card-body" style="padding: 10px">
            <div class="card-text">
              <b>Things to note:</b>
              <ul>
                <li><b>Environment variables</b> queue_url = os.environ["SQS_QUEUE_URL"]</li>
              </ul>
            </div>
          </div>
        </div>
        <br />

        <div class="card text-left">
          <div class="card-header">
            <h4 class="mb-0"><i class="fa-solid fa-circle-info"></i> Python snippets.</h4>
          </div>
          <div class="card-body" style="padding: 10px">
            <a href="https://github.com/Roche-Olivier/aws-examples/tree/main/Python" target="_blank">Python scripts on GITHUB</a>
          </div>
        </div>
        <br />
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>
