<template>
  <div id="content-section">
    <div class="container-fluid" style="">
      <div class="row">
        <div class="col-sm-3">
          <div class="card mb-2">
            <div class="card-header">
              <h4 class="mb-0"><i class="fa-solid fa-user-tie"></i> About me...</h4>
            </div>
            <div class="card-body">I'm a full stack developer with more than 25 years of application development, database management, microservices and web technologies experience.<br/>Cloud technologies is currently my absolute passion.</div>
          </div>

          <div class="card mb-2">
            <div class="card-header">
              <h4 class="mb-0"><i class="fa-solid fa-hand-holding-hand"></i> What I provide</h4>
            </div>
            <div class="card-body">
              <p>
                I can create a site for you at an incredible low cost per month, and maintain the site for you. You will never have to do anything.
              </p>
              <p>All you need to provide is the detail you would like to see on your site, your branding and logos.</p>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-header">
              <h4 class="mb-0"><i class="fa-solid fa-certificate"></i>Current certifications</h4>
            </div>
            <div class="card-body">
              <p>
                Below is the list of my current cloud certifications.
              </p>
              <img  src="../assets/images/saa-c02.png" class="rounded img-fluid linked-image" alt="AWS Certified Solutions Architect - Associate." onclick="window.open('https://www.credly.com/badges/7243fe1e-b4ec-4f87-b84f-0ed574ffbc21/public_url')"/>
              <img  src="../assets/images/ccp.png" class="rounded img-fluid linked-image" alt="AWS Certified cloud practitioner." onclick="window.open('https://www.credly.com/badges/d507ad53-a497-4eb0-99ef-d885a90e8c19/public_url')"/>
              <img  src="../assets/images/object_store.png" class="rounded img-fluid linked-image" alt="AWS Object Storage specialized"  onclick="window.open('https://www.credly.com/badges/237238c6-33f8-4504-9027-d1a8d40aef51/public_url')"/>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="card mb-2">
            <div class="card-header">
              <h4 class="mb-0"><i class="fa-solid fa-box-open"></i> What you will get</h4>
            </div>
            <div class="card-body">
              <ul>
                <li>AWS managed domains.</li>
                <br />
                <li>AWS managed SSL certificates</li>
                <br />
                <li>Extremely responsive site accross the globe ( Edge locations )</li>
                <br />
                <li>Site maintenance and 99.9% up time.</li>
                <br />
                <li>We manage the site for you.</li>
                <br />
              </ul>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-header">
              <h4 class="mb-0"><i class="fa-solid fa-sack-dollar"></i> Costs</h4>
            </div>
            <div class="card-body">
              <h4>Yearly cost:</h4>
              <li>Domain registration, depending on the domain for example a '.com' domain is $12.00.</li>
              <br />
              <router-link to="/domains"><button type="button" class="btn btn-dark">Full list of domains and pricing</button></router-link>

              <hr />
              <h4>Monthly cost:</h4>
              Monthly maintenance cost of $5.00<br/>
              In the case of a more involved web sites, that have databases etc included where users can input and save data AWS costs will be directly your responsibility and the monthly maintenance cost will be charged on top of that. Usage of individual services can be broken down by cost to facilitate the most cost effective system.
              <hr />
              <h4>Adhoc cost:</h4>
              Development of the site is included for the first page ( one main page / component ).<br />
              For more involved functionality a quote can be arrranged.
              <hr />
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card mb-2">
            <div class="card-header">
              <h4 class="mb-0"><i class="fa-solid fa-address-card"></i> More About me</h4>
            </div>
            <div class="card-body">
              <p>
                Email me at roche.olivier@cloudoutloud.net<br />
                or <br />
                <a href="mailto:roche.olivier@cloudoutloud.net?subject=Please contact me for a quote to build my web site."
                  ><button type="button" class="btn btn-dark">Click here to contact me</button></a
                >.
              </p>
              <p>Hope to hear from you soon.</p>
              <hr />
              <h5 class="card-title">Roche Olivier</h5>
              <p class="card-text">Developer with passion.</p>

              <div class="text-center">
                <img src="../assets/images/52068893.png" class="rounded img-fluid" alt="..." />
              </div>
              <hr />

              <div class="text-center">
                <a href="https://github.com/Roche-Olivier" target="_blank"> <img src="../assets/images/github.png" alt="github" /> </a>&nbsp;
                <a href="https://hub.docker.com/repositories" target="_blank"> <img src="../assets/images/dockerhub.png" alt="dockerhub" /> </a>&nbsp;
                <a href="https://roachmans.wordpress.com/" target="_blank"> <img src="../assets/images/wordpress.png" alt="wordpress" /> </a>&nbsp;
                <a href="https://www.linkedin.com/in/roche-olivier-b8138917/" target="_blank">
                  <img src="../assets/images/linkedin.png" alt="wordpress" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
