<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align: center">
        <span class="lsp-page-heading"> THE GREEN HUB </span><br />
        <span class="lsp-page-text-highlight">What is "THE GREEN HUB" ?</span><br />
        <span class="lsp-page-sub-heading">
          In the heart of innovation, where imagination meets reality, emerges the Green Hub - your digital newspaper of tomorrow. <br />Imagine a
          world where the green screen becomes a canvas for innovation, where stories are not just read but experienced. <br />With a visionary blend
          of tradition and technology, we have crafted a digital oasis for creativity, a space where the vibrant green of a digital screen breathes
          life into the art of storytelling. <br />It’s a space where pixels replace paper and where the future of news unfolds in real time.
          <br />Welcome to the Green Hub, where we believe that the evolution of news lies in embracing the limitless possibilities of the digital
          landscape. <br />We are a digital leadership platform, a place for future leaders to share, learn and grow.
        </span><br />
        <img src="../assets/images/greenhub_logo_250x250.png" class="lsp-image" alt="The green hub." />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
      <div class="col-sm-block col-md-block col-lg-10 col-xl-10" style="text-align: center">
        <span class="lsp-page-text-highlight">What is digital transformation ?</span><br />
        <span class="lsp-page-sub-heading">
          Let's imagine you have a room full of old-school file cabinets, each filled with stacks of papers. Now, picture the magical moment when you
          decide to wave a wand and turn all those papers into digital files on your computer. That's essentially what we mean by paper to digital
          transformation. It's the process of taking all those physical documents and converting them into digital formats that you can store,
          organize, and access on your computer or other devices. It's like upgrading from a manual typewriter to a sleek, high-tech laptop – making
          information more accessible, organized, and ready to be used in the digital world. <br />
        </span>
      </div>
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
    </div>

    <div class="row">
      <div class="col-sm-block col-md-block col-lg-4 col-xl-4" style="text-align: center"></div>
      <div class="col-sm-block col-md-block col-lg-4 col-xl-4" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <h5 class="card-title">
                <b>The green hub <br />digital transformation <br />dream TEAM !</b>
              </h5>
            </div>
            <img class="card-img-bottom" src="../assets/images/our_team.png" alt="art1img5" />
          </div>
        </div>
      </div>
      <div class="col-sm-block col-md-block col-lg-4 col-xl-4" style="text-align: center"></div>
    </div>
  </div>
</template>
