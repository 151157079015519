<template>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-3">

            </div>
            <div class="col-sm-6">

                <div class="card text-left">
                    <div class="card-header">
                        <h4 class="mb-0"><i class="fa-solid fa-circle-info"></i> Documentation.</h4>
                    </div>
                    <div class="card-body" style="padding:10px;">
                        <div class="card-text">
                            <b>General documentation.</b>
                        </div>
                        <div class="card-text">
                            This is a NODEJS library that you can use to call the pg back end to send queries to your
                            database and write out the name of the query and how long it took to execute on the
                            server.<br>
                            The pooler will get instantiated when the server spins up, and you can then use the
                            connection whenever you need a query.
                        </div>
                        <div class="card-text" style="margin-top:10px;margin-bottom:5px;">
                            Firstly you need to set up the connection to the pool and the pooling attributes.
                        </div>
                        <div style="background-color:lightgray;padding:5px;">
                            process.env.PG_HOST = "connection.rds.amazonaws.com"<br>
                            process.env.PG_PORT = "1000"<br>
                            process.env.PG_DB = "db_name"<br>
                            process.env.PG_MAX_CONN = "1"<br>
                            process.env.PG_POOL_IDLE_TIMEOUT = "30000"<br>
                            process.env.PG_CONNECTION_TIMEOUT = "3000"<br>
                            process.env.PG_QUERY_TIMEOUT = "3000"<br>
                            process.env.PG_STATEMENT_TIMEOUT = "3000"<br>
                            process.env.PG_STATEMENT_IDLE_TIMEOUT = "3000"<br>
                            process.env.PG_ALLOW_EXIT_ON_IDLE = "true"<br>
                            process.env.PG_USER = "user"<br>
                            process.env.PG_PW = "password"<br>
                        </div>
                        <div class="card-text" style="margin-top:10px;margin-bottom:5px;">
                            In the main server.js file add the code below to init the pooler for PG<br>
                        </div>
                        <div style="background-color:lightgray;padding:5px;">
                            // PGPOOL<br>
                            const pg_pooler = require('cloud-out-loud-pgpooler')<br>
                            pg_pooler.db_functions.initialize_pool();<br>
                        </div>

                        <div class="card-text" style="margin-top:10px;margin-bottom:5px;">
                            In your business logic layer or domain you can then just call the query as shown below<br>
                        </div>
                        <div style="background-color:lightgray;padding:5px;">
                            // BUSINESS LOGIC LAYER<br>
                            const pg_pooler = require('cloud-out-loud-pgpooler')<br>
                            exports.check_pg_server_health = async function () {<br>
                            <span style="margin-left:15px;"></span>var qs = "select * from y";<br>
                            <span style="margin-left:15px;"></span>var qd = "test query"<br>
                            <span style="margin-left:15px;"></span>return await pg_pooler.db_functions.async_query(qs, qd)<br>
                            };
                        </div>

                    </div>
                </div>
                <br />

                <div class="card text-left">
                    <div class="card-header">
                        <h4 class="mb-0"><i class="fa-solid fa-circle-question"></i> Frequently asked questions.</h4>
                    </div>
                    <div class="card-body" style="padding:10px;">
                        <p class="card-text">
                            More information will be added as the questions comes in
                        </p>

                    </div>
                </div>
                <br />

            </div>
            <div class="col-sm-3">

            </div>
        </div>
    </div>
</template>
