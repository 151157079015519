<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
      <div class="col-sm-block col-md-block col-lg-10 col-xl-10" style="text-align: center">
        <span class="lsp-page-heading"><i class="fa-solid fa-seedling"></i> GROW</span><br />
        <span class="lsp-page-text-highlight"
          >Growth, is a perpetual journey marked by continuous self-improvement and the development of those under one's guidance.</span
        ><br />
        <span class="lsp-page-sub-heading">
          A leader's commitment to personal and professional growth serves as the cornerstone for inspiring positive change within an organisation. It
          involves an ongoing pursuit of knowledge, the refinement of skills, and the cultivation of emotional intelligence. As leaders expand their
          capacities, they not only enhance their own effectiveness but also create an environment conducive to the growth of their team members. True
          leadership growth transcends mere achievement; it is a commitment to fostering a culture of learning, adaptability, and innovation. Through
          this dedication to growth, leaders not only elevate their own potential but also empower those around them to reach new heights of success.
          <br />
          <br/><a href="https://youtu.be/Ee6sTv8wJuw"  target="_blank">Hear it from the team !</a><br />
        </span>
      </div>
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
    </div>

    <div class="row">
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
      <div class="col-sm-block col-md-block col-lg-5 col-xl-5" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>Word search</b></h5>
            <p class="card-text">
              <br />
              A new year often brings new resolutions or intentions. This year let’s take it a step further. What will be your “Word of the Year” and
              how will it lead you to find your focus? Let’s embark on a crossword journey to unveil the perfect word to shape your 2024.
              <br />
              Find the following words in the puzzle.<br />
              Words are hidden <i class="fa-solid fa-up-down"></i> <i class="fa-solid fa-left-right"></i> and
              <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
              <br />
            </p>
            <div class="row">
              <div class="col-sm-4" style="text-align: center">
                ABUNDANCE<br />
                ACCEPTANCE<br />
                BALANCE<br />
                BOUNDARIES<br />
                CHANGE<br />
                DISCIPLINE<br />
                ELEVATE<br />
              </div>
              <div class="col-sm-4" style="text-align: center">
                FREEDOM<br />
                GROWTH<br />
                HAPPINESS<br />
                HEALING<br />
                HOPE<br />
                MINDFULNESS<br />
                POSITIVITY<br />
              </div>
              <div class="col-sm-4" style="text-align: center">
                RESILIENCE<br />
                SELF-CARE<br />
                TRANSFORM<br />
                WORTHY<br />
              </div>
            </div>
          </div>
          <img class="card-img-bottom" src="../assets/images/word_search.png" alt="word_search" />
        </div>
        <br />
        <br />
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>Births</b></h5>
            <p class="card-text">We welcome Rea and Rendani's baby into the world.</p>
            <br />
          </div>
          <img class="card-img-bottom" src="../assets/images/PHOTO-2024-01-31-18-30-16.jpg" alt="newborn" />
        </div>
        <br />
        <br />

        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>Bon Voyage</b></h5>
            <p class="card-text">We will miss our friends.</p>
            <br />
          </div>
          <img class="card-img-bottom" src="../assets/images/lsp_group.jpg" alt="thegroup" />
        </div>
        <br />
        <br />
      </div>
      <div class="col-sm-block col-md-block col-lg-5 col-xl-5" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>Book review</b></h5>
            <p class="card-text">'The Future of Work is Human' by Kevin Britz</p>
            <br />
            <br />
            <p class="card-text">
              As a participant in the Leading as a Specialist course, I recently had the pleasure of receiving Kevin Britz's enlightening book, "The
              Future of Work is Human." Packed with insights from a seasoned Solutionist, Speaker, Master Trainer, Executive Coach, and Author, this
              book is a breath of fresh air in the ever-changing landscape of modern work.<br />
              <br />
              Kevin’s message is crystal clear: while technology continues to revolutionise industries, the heart of the workforce remains undeniably
              human. In a world where automation threatens to replace mundane tasks, Kevin reminds us of the timeless value of creativity, critical
              thinking, and emotional intelligence. His words resonated with us, offering a reassuring perspective amidst the uncertainties of the
              future job market and how to take our skills and what we as leaders have to offer, to a new level.<br />
              <br />
              What makes Kevin’s book truly special is its relatable approach to preparing for tomorrow's workplace. Post covid, I appreciated Kevin’s
              candid discussions on remote work and the importance of adaptability. Through real-life examples and practical advice, Kevin empowers
              readers to embrace change and thrive in an ever-evolving professional landscape.<br />
              <br />
              Moreover, Kevin’s emphasis on personal growth and resilience struck a chord with me. By highlighting the significance of continuous
              learning and skill development, Kevin encourages readers to take ownership of their professional journey. As a leader, eager to carve
              out my path in the workforce, Kevin’s words serve as a guiding light, reminding me of the limitless possibilities that lie ahead.<br />
              <br />
              In essence, "The Future of Work is Human" is more than just a book – it's a roadmap for success in the modern workplace. Kevin’s
              infectious optimism and genuine passion for human potential make this book a must-read for students, leaders, and anyone navigating the
              complexities of the future job market. As I continue on my journey, armed with Kevin’s insights, I feel empowered to embrace the future
              with confidence, knowing that the essence of work will always be intrinsically human.<br />
              <br />
            </p>
          </div>
          <img class="card-img-bottom" src="../assets/images/2e9f5c38-52c3-4f04-ba72-7f00bb66bfe5.jpg" alt="bookreview" />
          <img class="card-img-bottom" src="../assets/images/IMG_4288.png" alt="bookreview" />
          <img class="card-img-bottom" src="../assets/images/IMG_6518.png" alt="bookreview" />
        </div>
        <br />
        <br />
      </div>
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
    </div>
  </div>
</template>
