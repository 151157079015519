<template>
<div class="lsp">
  <lsp_page_grow />
  </div>
</template>

<script>
// @ is an alias to /src
import lsp_page_grow from "@/components/lsp_page_grow.vue";

export default {
  name: "lsp-weather",
  components: { lsp_page_grow },
};
</script>
