<template>
<div class="pgpooler">
  <pg_pooler_page />
  </div>
</template>

<script>
// @ is an alias to /src
import pg_pooler_page from "@/components/pg_pooler_page.vue";

export default {
  name: "pgpooler",
  components: { pg_pooler_page },
};
</script>
