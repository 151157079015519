<template>
  <div class="lsp">
    <lsp_page />
  </div>
</template>

<script>
// @ is an alias to /src
import lsp_page from "@/components/lsp_page.vue";

export default {
  name: "lsp",
  components: { lsp_page },
};
</script>
