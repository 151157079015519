<template>
<div class="security">
  <security_page />
  </div>
</template>

<script>
// @ is an alias to /src
import security_page from "@/components/security_page.vue";

export default {
  name: "security",
  components: { security_page },
};
</script>
