<template>
<div class="node_fetch">
  <node_fetch_page />
  </div>
</template>

<script>
// @ is an alias to /src
import node_fetch_page from "@/components/node_fetch_page.vue";

export default {
  name: "node_fetch",
  components: { node_fetch_page },
};
</script>
