<template>
    <div class="cors">
        <cors_page />
    </div>
</template>

<script>
    // @ is an alias to /src
    import cors_page from "@/components/cors_page.vue";

    export default {
        name: "cors",
        components: { cors_page },
    };
</script>
