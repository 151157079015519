<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
      <div class="col-sm-block col-md-block col-lg-10 col-xl-10" style="text-align: center">
        <span class="lsp-page-heading"><i class="fa-solid fa-wand-magic-sparkles"></i> REINVENT</span><br />
        <span class="lsp-page-text-highlight">To reinvent is to embrace a transformative mindset that thrives on adaptation and innovation.</span><br />
        <span class="lsp-page-sub-heading">
          Leaders who seek to reinvent
          themselves are willing to challenge the status quo, discard outdated approaches, and explore new avenues for success. It involves a
          deliberate process of self-discovery, learning from experiences, and adopting fresh perspectives. Reinvention is not just about change for
          the sake of change but a strategic and purposeful evolution that enables leaders to stay relevant and resilient in the face of evolving
          challenges. By embracing reinvention, leaders inspire a culture of creativity and agility within their teams, fostering an environment where
          continuous improvement is not only encouraged but becomes a shared ethos driving sustained success.
        </span>
      </div>
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
    </div>


    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>POLITICAL</b></h5>
            <img src="../assets/images/weather_2.png" class="rounded img-fluid linked-image" style="text-align: center" alt="The future of work" />
            <h1 class="card-title"><b>12 'C</b></h1>
            <div class="card-text lsp-page-text-context" style="text-align: left">
              <ul>
                <li>Israel Palestine conflict</li>
                <li>Russia Ukraine war</li>
                <li>Corruption mitigation</li>
                <li>Political party infighting</li>
                <li>Upcoming elections</li>
                <li>Increased BRICS members</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>ECONOMIC</b></h5>
            <img src="../assets/images/weather_2.png" class="rounded img-fluid linked-image" style="text-align: center" alt="The future of work" />
            <h1 class="card-title"><b>25 'C</b></h1>
            <div class="card-text lsp-page-text-context" style="text-align: left">
              <ul>
                <li>Rand currency weakness</li>
                <li>Low foreign investments due to instability</li>
                <li>Increased interest rates</li>
                <li>Low consumer buying power</li>
                <li>Cost of living increases</li>
                <li>Value for money being more prioritized</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>SOCIAL</b></h5>
            <img src="../assets/images/weather_3.png" class="rounded img-fluid linked-image" style="text-align: center" alt="The future of work" />
            <h1 class="card-title"><b>25 'C</b></h1>
            <div class="card-text lsp-page-text-context" style="text-align: left">
              <ul>
                <li>Unemployment rate high</li>
                <li>Services hampered by poor infrastructure and accountability</li>
                <li>Short term and long-term survival</li>
                <li>Quality of education</li>
                <li>Diverse population requires diverse solutions</li>
                <li>Crime still a major concern</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>TECHNOLOGY</b></h5>
            <img src="../assets/images/weather_2.png" class="rounded img-fluid linked-image" style="text-align: center" alt="The future of work" />
            <h1 class="card-title"><b>18 'C</b></h1>
            <div class="card-text lsp-page-text-context" style="text-align: left">
              <ul>
                <li>Leading technology space in Africa</li>
                <li>Businesses needing to invest in agile digital solutions to compete</li>
                <li>Technology creating questions in recruitment frameworks</li>
                <li>Startups redefining existing conventional solutions</li>
                <li>Online shopping growth significant</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>LEGAL</b></h5>
            <img src="../assets/images/weather_1.png" class="rounded img-fluid linked-image" style="text-align: center" alt="The future of work" />
            <h1 class="card-title"><b>16 'C</b></h1>
            <div class="card-text lsp-page-text-context" style="text-align: left">
              <ul>
                <li>Regulation in the financial services sector</li>
                <li>2 Pot system for retirement</li>
                <li>Confidence in justice system persecute high ranking officials</li>
                <li>Concern over immigration control</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>ENVIRONMENT</b></h5>
            <img src="../assets/images/weather_4.png" class="rounded img-fluid linked-image" style="text-align: center" alt="The future of work" />
            <h1 class="card-title"><b>29 'C</b></h1>

            <div class="card-text lsp-page-text-context" style="text-align: left">
              <ul>
                <li>Vast environmental opportunities</li>
                <li>Tourism still significant for GDP</li>
                <li>Water quality challenges</li>
                <li>Energy provision challenges</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12" style="text-align: center">
        <span class="lsp-page-text-highlight"
          >Clive Vanderwagen - "Nothing happens for a reason" <br />
          <a href="https://www.ebizradio.com/?s=clive+vanderwagen" target="_blank">More podcasts with Clive</a> <br />
        </span>
        <span class="lsp-page-sub-heading">
          "Nothing happens for a reason, but everything works for good – if we choose to let it. Power comes from surrender and acceptance. I’m
          married to an amazing man and have four demanding dachshunds that make me laugh every day. I run my own business, training and coaching
          people to improve their business relationships. I worked in training and human resources in the corporate space for a number of years prior
          to deciding to venture out on my own. I spend my days speaking to people online (or in the boardroom pre-Covid), and love working and
          interacting with people as often as I can. I’m a complete extrovert – my energy is rejuvenated by being around others. Before working in
          training, I worked in media (I have a Masters degree in Journalism and Media Studies), and before that I worked in the entertainment
          industry as an actor and actors’ agent (I have an Honours degree in Drama). As a trainer you often ask people to tell you one thing people
          don’t know about you, and mine is that I’ve held Madonna’s hand. I’m pretty sure she won’t remember, but I’ll never forget it. (Can you tell
          I’m a Madonna fan?)" ~Clive Vanderwagen<br />
        </span>

        <a href="https://www.linkedin.com/in/clivevanderwagen/" target="_blank"> Clive's linkedin profile </a> <br />

        <iframe
          width="350"
          height="315"
          src="https://www.youtube.com/embed/8SAZR6k-M-8"
          title="Nothing happens for a reason | Mr Clive Vanderwagen | TEDxRiversdale"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe
        ><br /><br />
      </div>
      <br />
    </div>
  </div>
</template>
