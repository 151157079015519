<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
      <div class="col-sm-block col-md-block col-lg-10 col-xl-10" style="text-align: center">
        <span class="lsp-page-heading"><i class="fa-solid fa-compass"></i> REFLECT</span><br />
        <span class="lsp-page-text-highlight">Reflection, is the invaluable pause that propels growth.</span><br />
        <span class="lsp-page-sub-heading">
          Leaders who prioritize reflection engage in a deliberate and mindful process of reviewing their experiences, decisions, and outcomes. <br />
          This introspective practice enables them to distill lessons from successes and setbacks alike, fostering self-awareness and resilience.
          <br />
          Through reflection, leaders refine their strategies, enhance their emotional intelligence, and develop a deeper understanding of their
          leadership style. It is the cornerstone of continuous improvement, allowing leaders to evolve and inspire with wisdom gained from thoughtful
          contemplation. <br />
          In the quiet moments of reflection, leaders lay the foundation for enduring success and contribute to the development of a more enlightened
          and effective leadership approach. <br />
        </span>
      </div>
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
    </div>

    <div class="row">
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
      <div class="col-sm-6 col-md-6 col-lg-5 col-xl-5" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>Joke of the day</b></h5>
          </div>
          <img class="card-img-bottom" src="../assets/images/comic_strip.png" alt="art1img5" />
        </div>
        <br />
        <br />
        <div class="card">
          <img class="card-img-top" src="../assets/images/escape_from_ras.png" alt="art1img5" />
          <div class="card-body">
            <h5 class="card-title"><b>Escape From Alcat-RAS?</b></h5>
            <p class="card-text">
              <br />
              The thought of prison is arguably one that brings about feelings of anxiety, fear and a disconnect from the realities of one’s current
              life. We often go about our personal lives with the belief that we are indeed free in the truest form of the word, and that the very
              mention of prison relates only to those deserving of such a place. In the context of the justice system this would be correct, however,
              what would you do if you found out that you were indeed a prisoner of your mind!<br />
              <br />
              Enter your Reticular Activating System, otherwise known as your RAS. It is part of the larger brain network and receives information
              from different sensory systems sending it to the cerebral cortex. An ‘untrained’ RAS can imprison most people in feelings of
              uncertainty, doubt, fear and even sadness if a person is not conscious of the impact it has. In the simplest of terms, W Siong Lai
              describes the RAS as “connecting the brain stem to the cerebral cortex and plays a key role in regulating wakefulness and sleep-wake
              transitions.”<br />
              <br /><br /><i>
                “The RAS is a bundle of nerves at the base of your brain stem that acts as a filter between your conscious mind and your subconscious
                mind. It filters out millions of pieces of data that your brain is processing per second, and only allows what is important and of
                interest to you into your awareness”</i
              ><br />

              <br />
              As human beings we process a significant amount of information daily and our minds have become trained to first prioritise and respond
              to information we deem important. In addition, our RAS ensures that we place focus on the activities and experiences that illicit some
              sense of deep emotionally connection and our behaviour may be influenced by these experiences. A few examples are when you have just
              bought a car and suddenly notice more of the same models on the road. Or you could be in a room full of loud people and not be cognizant
              of the noise at all until someone calls your name. Amidst all the noise you’re somehow able to pick up your name being called. Or, when
              one or two things go wrong you suddenly believe everything is going wrong and you see the negative in all situations. These are examples
              of your RAS being activated.
              <br />
              <i
                >“Knowing how the brain works is the new frontier for better business. You can use it to drive real, sustainable change in your
                company because human behaviour and performance are at the core of most of the hindrances and opportunities in companies”
                LifeXchange</i
              ><br />
              <br />
              The good news though is that because it almost acts as a personal radar, you can tune this radar to help you achieve your goals and have
              a more positive outlook on life. The power of positive thinking and affirmations are greater than most believe and there are several
              ways we can influence our RAS.
              <br />
              * Say your goals out loud<br />
              * Imagine yourself achieving them<br />
              * Disrupt negative thinking patterns<br />
              * Look at images of what you want<br />
              * Visualize each step towards the final objective<br />
              <br />
              Blaine Oelkers in his Ted Talk refers to the energy you expel into the world being the energy you will receive. He uses the acronym
              WYTAYBA which stands for “What You Think About, You Bring About” to illustrate the importance of positive thinking and how it can change
              your outlook. <br />
              <br />
            </p>
          </div>
        </div>
        <br />
        <br />
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>Some of our mentors.</b></h5>
          </div>
          <img class="card-img-bottom" src="../assets/images/some_mentors.jpg" alt="art1img5" />
        </div>
        <br />
        <br />
      </div>
      <div class="col-sm-6 col-md-6 col-lg-5 col-xl-5" style="text-align: center">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><b>Rest In Paper</b></h5>
            <p class="card-text">
              <br />
              In the age of screens aglow,<br />
              Where digital winds swiftly blow,<br />
              We bid adieu, with fond embrace, <br />
              To paper's reign, its quiet grace.<br />
              <br />
              Efficiency's call, it whispers near,<br />
              As bytes and pixels now appear,<br />
              Replacing pages, once so dear, <br />
              In this digital frontier.<br />
              <br />
              Farewell to stacks of bound delight,<br />
              To ink-stained fingers, a fading sight,<br />
              For in the realm of ones and zeroes,<br />
              Paper's role now gently slows.<br />
              <br />
              Economic winds, they softly blow,<br />
              As budgets breathe a sigh, and grow,<br />
              Digital shores, they promise gold,<br />
              In realms of data, yet untold.<br />
              <br />
              From forests spared, to pockets lined,<br />
              The benefits of this new kind,<br />
              Where bits and bytes dance in the air,<br />
              Paper's legacy, we now declare.<br />
              <br />
              But though we bid adieu, and part,<br />
              With memories etched, in every heart,<br />
              Rest in paper, silent friend, <br />Your legacy, has come to an end.<br />
            </p>
          </div>
          <img class="card-img-bottom" src="../assets/images/Orbituary.png" alt="art1img5" />
        </div>
        <br />
        <br />
        <div class="card">
          <img class="card-img-top" src="../assets/images/rewire_your_brain.png" alt="art1img5" />
          <div class="card-body">
            <h5 class="card-title"><b>Rewire Your Brain: A Journey to Intentional Living</b></h5>
            <p class="card-text">
              <br />
              In the pursuit of a more intentional and purposeful life, the key lies in rewiring our brain and updating our internal software through
              the transformative influence of neuroscience, meditation, and journaling. Let's delve into how these practices can elevate you into a
              superior version of yourself, both personally and professionally. <br />
              <br />
              1. Neuroscience: Cultivating a Purposeful Mindset <br />
              <br />
              Our brains exhibit remarkable adaptability, and a grasp of neuroplasticity principles can empower you to intentionally shape your
              thoughts and behaviours. Begin by setting clear intentions—define your goals and values. This foundation serves as a roadmap for your
              brain, fostering purposeful decision-making and actions. <br />
              <br />
              *Tip: Routinely revisit and refine your goals to keep your brain engaged and focused on what genuinely matters to you. The act of
              writing down your goals is a potent tool for determining your life's desires. <br />
              <br />
              2. Meditation: Fostering Mindfulness for Professional Growth <br />
              <br />
              Meditation emerges as a potent tool for cultivating mindfulness, a heightened state of awareness that positively impacts your brain's
              structure and function. Reduce stress and enhance emotional regulation by incorporating regular meditation into your routine. <br />
              <br />
              *Tip:Dedicate a focused 15 minutes each morning to quiet time for meditation. If your mind feels too busy, explore guided meditations
              tailored to your needs. This practice reinforces areas of your brain responsible for memory, learning, attention, and self-awareness.
              <br />
              <br />
              3. Journaling: The Transformative Power of Reflection <br />
              <br />
              Journaling serves as a form of mental debugging, enabling you to identify and reprogram limiting beliefs, thought patterns, and
              behaviours. <br />
              <br />
              *Tip: Spend a few minutes each day writing down five things you are grateful for. This small investment yields significant returns in
              self-awareness and growth. Appreciating the beauty in simple aspects of life can pave the way for a more positive day. <br />
              <br />
              As you embark on this intentional living journey, reflect daily with the question, "Am I better today than I was yesterday?" This query
              serves as a powerful daily reflection, urging you to assess progress and make intentional choices aligning with your personal and
              professional aspirations. As Lewis Howes wisely puts it, "You are the only person who can give yourself what you want."

              <br />
            </p>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div class="col-sm-block col-md-block col-lg-1 col-xl-1" style="text-align: center"></div>
    </div>
  </div>
</template>
