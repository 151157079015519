<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="/">
      <h3><img src="../assets/images/man1.png" alt="avatar" /> CloudOutLoud</h3>
    </a>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="/#/domains">Domain costs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="/#/aws_py_snippets">AWS .py snippets</a>
        </li>
      </ul>
    </div>

    <a class="navbar-brand" href="https://lsp.cloudoutloud.net" target="_blank">
      <h3><img src="../assets/images/greenhub_logo_25x25.png" alt="green hub" /></h3>
    </a>

  </nav>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .rtr-link {
  color: white;
  cursor: pointer;
}
.rtr-link:hover {
  color: rgb(226, 226, 226);
  cursor: pointer;
  text-decoration: none;
} */
</style>
