<template>

	<div class="container-fluid">
		<div class="row">
			<div class="col-sm-3">

			</div>
			<div class="col-sm-6">

				<div class="card text-left">
					<div class="card-header">
                         <h4 class="mb-0"><i class="fa-solid fa-hand-holding-dollar"></i> Domain prices for 2022.</h4>
					</div>
					<div class="card-body" style="padding:10px;">
						<p class="card-text">
							<ul>
.ac - $76.00<br/>
.academy - $12.00<br/>
.accountants - $94.00<br/>
.adult - $100.00<br/>
.agency - $19.00<br/>
.apartments - $47.00<br/>
.associates - $29.00<br/>
.au - $15.00<br/>
.auction - $29.00<br/>
.band - $22.00<br/>
.bargains - $30.00<br/>
.be - $9.00<br/>
.berlin - $66.00<br/>
.bike - $32.00<br/>
.bingo - $47.00<br/>
.biz - $16.00<br/>
.black - $66.00<br/>
.blue - $22.00<br/>
.boutique - $30.00<br/>
.builders - $32.00<br/>
.business - $18.00<br/>
.buzz - $37.00<br/>
.ca - $13.00<br/>
.cab - $32.00<br/>
.cafe - $31.00<br/>
.camera - $46.00<br/>
.camp - $46.00<br/>
.capital - $47.00<br/>
.cards - $29.00<br/>
.care - $29.00<br/>
.careers - $35.00<br/>
.cash - $29.00<br/>
.casino - $141.00<br/>
.catering - $29.00<br/>
.cc - $12.00<br/>
.center - $21.00<br/>
.ceo - $74.00<br/>
.ch - $13.00<br/>
.chat - $29.00<br/>
.cheap - $30.00<br/>
.church - $29.00<br/>
.city - $19.00<br/>
.claims - $47.00<br/>
.cleaning - $46.00<br/>
.click - $3.00<br/>
.clinic - $47.00<br/>
.clothing - $32.00<br/>
.cloud - $25.00<br/>
.club - $16.00<br/>
.co - $25.00<br/>
.co.nz - $24.00<br/>
.co.uk - $9.00<br/>
.co.za - $13.00<br/>
.coach - $47.00<br/>
.codes - $35.00<br/>
.coffee - $32.00<br/>
.college - $69.00<br/>
.com - $12.00<br/>
.com.au - $15.00<br/>
.com.mx - $34.00<br/>
.community - $29.00<br/>
.company - $18.00<br/>
.computer - $32.00<br/>
.condos - $49.00<br/>
.construction - $32.00<br/>
.consulting - $12.00<br/>
.contractors - $32.00<br/>
.cool - $30.00<br/>
.coupons - $51.00<br/>
.credit - $94.00<br/>
.creditcard - $141.00<br/>
.cruises - $49.00<br/>
.cz - $10.00<br/>
.dance - $22.00<br/>
.dating - $49.00<br/>
.de - $9.00<br/>
.deals - $29.00<br/>
.delivery - $47.00<br/>
.democrat - $30.00<br/>
.dental - $47.00<br/>
.diamonds - $35.00<br/>
.digital - $29.00<br/>
.direct - $29.00<br/>
.directory - $21.00<br/>
.discount - $29.00<br/>
.dog - $46.00<br/>
.domains - $32.00<br/>
.education - $21.00<br/>
.email - $25.00<br/>
.energy - $94.00<br/>
.engineering - $47.00<br/>
.enterprises - $32.00<br/>
.equipment - $21.00<br/>
.es - $10.00<br/>
.estate - $32.00<br/>
.eu - $13.00<br/>
.events - $30.00<br/>
.exchange - $29.00<br/>
.expert - $49.00<br/>
.exposed - $19.00<br/>
.express - $31.00<br/>
.fail - $29.00<br/>
.farm - $32.00<br/>
.fi - $24.00<br/>
.finance - $47.00<br/>
.financial - $47.00<br/>
.fish - $29.00<br/>
.fitness - $29.00<br/>
.flights - $49.00<br/>
.florist - $32.00<br/>
.fm - $92.00<br/>
.football - $19.00<br/>
.forsale - $29.00<br/>
.foundation - $30.00<br/>
.fr - $12.00<br/>
.fund - $47.00<br/>
.furniture - $47.00<br/>
.futbol - $12.00<br/>
.fyi - $20.00<br/>
.gallery - $21.00<br/>
.gg - $75.00<br/>
.gift - $20.00<br/>
.gifts - $19.00<br/>
.glass - $46.00<br/>
.global - $71.00<br/>
.gold - $101.00<br/>
.golf - $51.00<br/>
.graphics - $21.00<br/>
.gratis - $19.00<br/>
.green - $71.00<br/>
.gripe - $29.00<br/>
.guide - $29.00<br/>
.guru - $25.00<br/>
.haus - $29.00<br/>
.healthcare - $47.00<br/>
.help - $16.00<br/>
.hiv - $254.00<br/>
.hockey - $51.00<br/>
.holdings - $35.00<br/>
.holiday - $35.00<br/>
.host - $65.00<br/>
.house - $32.00<br/>
.im - $19.00<br/>
.immo - $29.00<br/>
.immobilien - $30.00<br/>
.in - $15.00<br/>
.industries - $29.00<br/>
.info - $23.00<br/>
.ink - $29.00<br/>
.institute - $21.00<br/>
.insure - $47.00<br/>
.international - $21.00<br/>
.investments - $94.00<br/>
.io - $71.00<br/>
.irish - $36.00<br/>
.it - $15.00<br/>
.jewelry - $51.00<br/>
.jp - $90.00<br/>
.kaufen - $30.00<br/>
.kim - $22.00<br/>
.kitchen - $46.00<br/>
.kiwi - $32.00<br/>
.land - $32.00<br/>
.lease - $47.00<br/>
.legal - $47.00<br/>
.lgbt - $55.00<br/>
.life - $12.00<br/>
.lighting - $21.00<br/>
.limited - $29.00<br/>
.limo - $50.00<br/>
.link - $5.00<br/>
.live - $12.00<br/>
.loan - $31.00<br/>
.loans - $94.00<br/>
.lol - $16.00<br/>
.maison - $49.00<br/>
.management - $21.00<br/>
.marketing - $32.00<br/>
.mba - $31.00<br/>
.me - $25.00<br/>
.me.uk - $8.00<br/>
.media - $12.00<br/>
.memorial - $47.00<br/>
.mobi - $30.00<br/>
.moda - $22.00<br/>
.money - $29.00<br/>
.mortgage - $43.00<br/>
.movie - $306.00<br/>
.mx - $34.00<br/>
.name - $9.00<br/>
.net - $11.00<br/>
.net.au - $15.00<br/>
.net.nz - $24.00<br/>
.network - $19.00<br/>
.news - $12.00<br/>
.ninja - $18.00<br/>
.nl - $10.00<br/>
.onl - $15.00<br/>
.online - $25.00<br/>
.org - $12.00<br/>
.org.nz - $24.00<br/>
.org.uk - $9.00<br/>
.partners - $49.00<br/>
.parts - $29.00<br/>
.photo - $16.00<br/>
.photography - $21.00<br/>
.photos - $21.00<br/>
.pics - $16.00<br/>
.pictures - $10.00<br/>
.pink - $22.00<br/>
.pizza - $47.00<br/>
.place - $29.00<br/>
.plumbing - $46.00<br/>
.plus - $31.00<br/>
.poker - $66.00<br/>
.porn - $100.00<br/>
.pro - $25.00<br/>
.productions - $30.00<br/>
.properties - $30.00<br/>
.property - $29.00<br/>
.pub - $22.00<br/>
.qpon - $15.00<br/>
.recipes - $35.00<br/>
.red - $22.00<br/>
.reise - $101.00<br/>
.reisen - $19.00<br/>
.rentals - $30.00<br/>
.repair - $32.00<br/>
.report - $19.00<br/>
.republican - $29.00<br/>
.restaurant - $47.00<br/>
.reviews - $22.00<br/>
.rip - $17.00<br/>
.rocks - $12.00<br/>
.ruhr - $30.00<br/>
.run - $20.00<br/>
.sale - $29.00<br/>
.sarl - $29.00<br/>
.school - $29.00<br/>
.schule - $19.00<br/>
.se - $23.00<br/>
.services - $12.00<br/>
.sex - $100.00<br/>
.sexy - $39.00<br/>
.sh - $76.00<br/>
.shiksha - $22.00<br/>
.shoes - $46.00<br/>
.show - $31.00<br/>
.singles - $30.00<br/>
.soccer - $20.00<br/>
.social - $32.00<br/>
.solar - $46.00<br/>
.solutions - $12.00<br/>
.studio - $12.00<br/>
.style - $29.00<br/>
.sucks - $282.00<br/>
.supplies - $19.00<br/>
.supply - $19.00<br/>
.support - $21.00<br/>
.surgery - $47.00<br/>
.systems - $21.00<br/>
.tattoo - $47.00<br/>
.tax - $47.00<br/>
.taxi - $51.00<br/>
.team - $31.00<br/>
.technology - $21.00<br/>
.tennis - $47.00<br/>
.theater - $51.00<br/>
.tienda - $50.00<br/>
.tips - $21.00<br/>
.tires - $94.00<br/>
.today - $21.00<br/>
.tools - $29.00<br/>
.tours - $51.00<br/>
.town - $29.00<br/>
.toys - $46.00<br/>
.trade - $29.00<br/>
.training - $27.00<br/>
.tv - $32.00<br/>
.uk - $9.00<br/>
.university - $47.00<br/>
.uno - $15.00<br/>
.us - $15.00<br/>
.vacations - $35.00<br/>
.vc - $33.00<br/>
.vegas - $57.00<br/>
.ventures - $47.00<br/>
.vg - $35.00<br/>
.viajes - $49.00<br/>
.video - $22.00<br/>
.villas - $47.00<br/>
.vision - $29.00<br/>
.voyage - $50.00<br/>
.watch - $37.00<br/>
.website - $15.00<br/>
.wien - $50.00<br/>
.wiki - $30.00<br/>
.works - $30.00<br/>
.world - $12.00<br/>
.wtf - $29.00<br/>
.xyz - $12.00<br/>
.zone - $32.00<br/>

							</ul>
						</p>
					</div>
				</div>
				<br />

			</div>
			<div class="col-sm-3">

			</div>
		</div>
	</div>
</template>