<template>
  <div class="home">
    <main_page />
  </div>
</template>

<script>
// @ is an alias to /src
import main_page from "@/components/main_page.vue";

export default {
  name: "Home",
  components: { main_page },
};



</script>

