<template>
<div class="moment">
  <moment_page />
  </div>
</template>

<script>
// @ is an alias to /src
import moment_page from "@/components/moment_page.vue";

export default {
  name: "moment",
  components: { moment_page },
};
</script>
