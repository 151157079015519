
<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-3">

            </div>
            <div class="col-sm-6">

                <div class="card text-left">
                    <div class="card-header">
                        <h4 class="mb-0"><i class="fa-solid fa-circle-info"></i> Documentation.</h4>
                    </div>
                    <div class="card-body" style="padding:10px;">
                        <div class="card-text">
                            <b>General documentation.</b>
                        </div>
                        <div class="card-text">
                            This is a NODEJS library that you can use to set up passport and JWT token.
                        </div>
                        <div class="card-text" style="margin-top:10px;margin-bottom:5px;">
                            Firstly you need to set up the process environment variables.
                        </div>
                        <div style="background-color:lightgray;padding:5px;">
                            To be completed
                        </div>
                    </div>
                </div>
                <br />

                <div class="card text-left">
                    <div class="card-header">
                        <h4 class="mb-0"><i class="fa-solid fa-circle-question"></i> Frequently asked questions.</h4>
                    </div>
                    <div class="card-body" style="padding:10px;">
                        <p class="card-text">
                            More information will be added as the questions comes in
                        </p>

                    </div>
                </div>
                <br />

            </div>
            <div class="col-sm-3">

            </div>
        </div>
    </div>
</template>
