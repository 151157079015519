<template>
<div class="Domains">
  <domain_prices_page />
  </div>
</template>

<script>
// @ is an alias to /src
import domain_prices_page from "@/components/domain_prices_page.vue";

export default {
  name: "Domains",
  components: { domain_prices_page },
};
</script>
