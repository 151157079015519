<template>
<div class="lsp">
  <lsp_page_reengage />
  </div>
</template>

<script>
// @ is an alias to /src
import lsp_page_reengage from "@/components/lsp_page_reengage.vue";

export default {
  name: "lsp-sport",
  components: { lsp_page_reengage },
};
</script>
