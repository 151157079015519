<template>
<div class="lsp">
  <aws_page_py_snippets />
  </div>
</template>

<script>
// @ is an alias to /src
import aws_page_py_snippets from "@/components/aws_py_snippets_page.vue";

export default {
  name: "aws-py-snippets",
  components: { aws_page_py_snippets },
};
</script>
