<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-3">

            </div>
            <div class="col-sm-6">

                <div class="card text-left">
                    <div class="card-header">
                        <h4 class="mb-0"><i class="fa-solid fa-circle-info"></i> Documentation.</h4>
                    </div>
                    <div class="card-body" style="padding:10px;">
                        <div class="card-text">
                            <b>General documentation.</b>
                        </div>
                        <div class="card-text">
                            This is a NODEJS library that you can use to create response headers with values defined in the
                            process.env file.
                        </div>
                        <div class="card-text" style="margin-top:10px;margin-bottom:5px;">
                            Firstly you need to set up the environment. This is the values that will be referenced in the package.<br>
                            The values can be abstracted to anywhere you would like to store your env variables.<br>
                            It can be added to a kubernetes configuration or secrets file.
                        </div>
                        <div style="background-color:lightgray;padding:5px;">
                            process.env.BASE_SERVER_URL = "http://localhost:3000"<br>
                            process.env.API_URL_HELMET_PATH = "http://localhost:3001"<br>
                            process.env.UI_PACKAGE_PROVIDER_PATH = "https://cdnjs.cloudflare.com"<br>
                        </div>
                        <div class="card-text" style="margin-top:10px;margin-bottom:5px;">
                            In the main server.js file add the code below to init the helmet settings in the wrapper.<br>
                            This will allow only access to the application and defined sites to your code or front end.<br>
                            The default helmet values are used for the variables that is not defined.
                        </div>
                        <div style="background-color:lightgray;padding:5px;">
                            // HELMET
                            const _response_headers_config = require('cloud-out-loud-reponse-header-config')<br>
                            _response_headers_config.response_headers.init(app);
                        </div>

                        <div class="card-text" style="margin-top:10px;margin-bottom:5px;">
                            The complete code would then look something like this depending on your variables and settings.
                        </div>
                        <div style="background-color:lightgray;padding:5px;">
                            process.env.BASE_SERVER_URL = "http://localhost:3000"<br>
                            process.env.API_URL_HELMET_PATH = "http://localhost:3001"<br>
                            process.env.UI_PACKAGE_PROVIDER_PATH = "https://cdnjs.cloudflare.com"<br>
                            <br>
                            const express = require("express");<br>
                            const app = express();<br>
                            <br>
                            const _response_headers_config = require('cloud-out-loud-reponse-header-config')<br>
                            _response_headers_config.response_headers.init(app);<br>
                        </div>

                    </div>
                </div>
                <br />

                <div class="card text-left">
                    <div class="card-header">
                        <h4 class="mb-0"><i class="fa-solid fa-circle-question"></i> Frequently asked questions.</h4>
                    </div>
                    <div class="card-body" style="padding:10px;">
                        <p class="card-text">
                            More information will be added as the questions comes in
                        </p>

                    </div>
                </div>
                <br />

            </div>
            <div class="col-sm-3">

            </div>
        </div>
    </div>
</template>
