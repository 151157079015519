<template>
<div class="responseHeaders">
  <response_headers_page />
  </div>
</template>

<script>
// @ is an alias to /src
import response_headers_page from "@/components/response_headers_page.vue";

export default {
  name: "responseHeaders",
  components: { response_headers_page },
};
</script>
