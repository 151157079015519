<template>
  <nav class="navbar fixed-bottom navbar-dark bg-dark">
    <!-- <div style="text-align: left;">
      <a href="https://www.facebook.com/profile.php?id=61555727916326&mibextid=LQQJ4d" target="_blank"><img src="../assets/images/media_fb.png" alt="Facebook" style="width:40px;height:40px;"></a>
      &nbsp;&nbsp;
      <a href="https://www.instagram.com/thegreenhub2024/" target="_blank"><img src="../assets/images/media_insta.png" alt="Instagram" style="width:40px;height:40px;"></a>
      &nbsp;&nbsp;
      <a href="https://www.youtube.com/@TheGreenHub2024" target="_blank"><img src="../assets/images/media_youtube.png" alt="Youtube" style="width:40px;height:40px;"></a>
      &nbsp;&nbsp;
      <a href="https://www.tiktok.com/@greenhub094" target="_blank"><img src="../assets/images/media_tiktok.png" alt="tiktok" style="width:40px;height:40px;"></a>
    </div> -->

    <a class="nav-link my-sm-0 xs" href="mailto:roche.olivier@cloudoutloud.net?subject=Please contact me for a quote to build my web site.">AWS solution by: CloudOutLoud.net</a>
  </nav>
</template>

<script>
export default {
  name: "FooterNav",
  props: {
    banner_text: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
