<template>
<div class="lsp">
  <lsp_page_reinvent />
  </div>
</template>

<script>
// @ is an alias to /src
import lsp_page_reinvent from "@/components/lsp_page_reinvent.vue";

export default {
  name: "lsp-weather",
  components: { lsp_page_reinvent },
};
</script>
